const GENRES = [
    {name: 'Kuduro'}, 
    {name: 'Semba'}, 
    {name: 'Deep House'}, 
    {name: 'Afro House'}, 
    {name: 'Pagode'}, 
    {name: 'HipHop/Rap'}, 
    {name: 'Rap/Trap'},
    {name: 'R&B'},
    {name: 'Pagode'}, 
    {name: 'Latino'}, 
    {name: 'Kizomba'}, 
    {name: 'Reggae'}, 
    {name: 'Rock'}, 
    {name: 'Dj Mix'}, 
    {name: 'Naija'}, 
    {name: 'Pop'}, 
    {name: 'Jazz'}, 
    {name: 'Amapiano'}, 
    {name: 'Afrobeat'}, 
    {name: 'Scif'}, 
    {name: 'Instrumental'},
    {name: 'Gospel'} 

  ]
  export default GENRES;