import '../../Styles/App.css';
import '../../Styles/Admin.css';
import './styles/genres.css';

import { useCallback, useEffect, useState } from 'react';
import izenuAPI from '../../services/izenuAPI';
import {BsFileEarmarkImage} from 'react-icons/bs';
import { Formik, Form, ErrorMessage, Field } from 'formik';

import axios from "axios";

const token = localStorage.getItem('token');


//izenuApi.defaults.headers.Authorization = 'Bearer ' + token;
const uuid = () =>{

    const alfaNumeric = '1234567890ABCDEFGHIJKLMNOPQRSTUVXWYZabcdefghijklmnopkrstuvxwyz';
    let str = alfaNumeric.split("");
    let str2 = '';
    for(var i = 0; i < 8; i++){
        str2 += '' + str[Math.ceil(Math.random() * str.length)];
    }
    return str2;
}

export default function Genres(){
    const [image, setImage] = useState(null);
    const [genres, setGenres] = useState([]);
    const  [loading, setLoading] = useState(false);
    const [fetchingData, setFetchingData] = useState(true);
    const handleSubmitForm = useCallback(async (v)=>{
        setLoading(true);
        if(image == null){
            alert("Seleciona a capa do genero");
            return;
        }
        const id = uuid();
        const d = new Date();

        const date = {day: d.getUTCDate(), year: d.getUTCFullYear(), month: d.getUTCMonth() + 1};

        const form = new FormData();
        const cover_name = `genre_cover_${date.year}_${date.month}_${date.day}_${id}.${image.file.type.split('/')[1]}`;
        
        form.append(`files`, image.file);
        form.append(`key`, 'music_genre/' + cover_name);

        const {data: uploadResp} = await axios.post("https://server.izenu.net/genre/upload_cover", form,
        {
            headers: {
                    'Content-Type': 'multipart/form-data',
            }
        }
        );
        const {data: resp} = await izenuAPI.post("https://www.izenu.ao/api/admin/genre/insert.php", {genre: v.genre, image:cover_name});
        
        if(resp.response){
            alert("Genero adiconado com sucesso");
        }else{
            alert("Impossível adicionar genro tente mais tarde");
            setImage(null);
        }
        setLoading(false);
    },[image]);


    const handleCoverChange =  useCallback((event)=>{
        
        const file = event.currentTarget.files[0];
            console.log(file);
        const types = ['png','jpg', 'jpeg'];

        //alert(types.indexOf(file.name.split('.')[1].toLowerCase()));
        
        if(types.indexOf(file.name.split('.').slice(-1)[0].toLowerCase()) == -1){
            alert('Tipo de ficheiro invalido');
            return;
        }

        const reader = new FileReader();
        
        reader.onload = ()=>{
            setImage({file:file, result:reader.result});
        }
        reader.readAsDataURL(file);
    },[image]);

    const fetchAll = useCallback(async ()=>{
        
        try{
            const {data: r} = await izenuAPI.get('https://www.izenu.ao/api/admin/genre/fetchAll.php');
            
            if(r.response){
                setGenres(r.data);
            }
            
        }catch(error){
            console.log(error);
        
        }
        setFetchingData(false);
    });

    useEffect(()=>{
        fetchAll();
    },[]);
    if(fetchingData){
        return (<div className="justify-center items-center flex flex-col h-full border p-5 rounded-lg">
                        <div role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    <h1 className="text-amber-500 font-light text-3xl">A carregar...</h1>
                    
                </div>)
    }
    if(loading){
        return (<div className="justify-center items-center flex flex-col h-full border p-5 rounded-lg">
                        <div role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">A adicionar genero...</span>
                        </div>
                   
                    
                </div>)
    }
    return (<div className="p-10">
                <div className="p-10 flex flex-row space-x-4">
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Generos de música na app
                            </h1>
                         </div>
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Adicionar novo genero
                            </h1>
                         </div>
                </div>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative border flex-1 max-h-96 overflow-y-auto gap-2  p-5">
                        <div className="grid grid-cols-2 gap-4 relative">
                            {genres.map((e,i)=>{
                               
                                return(
                                    <div className="border relative w-full p-2 flex gap-5 flex-row mb-3 items-center justify-center  rounded-lg">
                                        <img className="h-20 w-full rounded-lg object-cover" src={e.image}/> 
                                        <div className="position absolute w-full h-20 justify-center items-center   ">
                                            <h3 className='block text-slate-50 text-2xl'>
                                                {e.name}
                                            </h3>
                                        </div>  
                                    </div>
                                )
                            })}
                        </div>    
                    </div>

                    <div className="relative flex-1 max-h-96 overflow-y-auto gap-2 border p-5 rounded-lg">
                       
                        <Formik 
                            initialValues={{genre:''}}
                            validate={values => {

                                const errors = {};
        
                                if (values.genre.length == 0) {
                                    errors.genre = 'Campo obrigatorio';
                                }
                        
                                return errors;
                        
                            }}
                            
                            onSubmit={(values, { setSubmitting }) => {
                                   
                                setTimeout(() => {
                        
                                    //alert(JSON.stringify(values, null, 2));
                                    handleSubmitForm(values);      
                                    setSubmitting(false);
                        
                                }, 400);
                        
                                }}
                            >

                            {({isSubmitting,  values,

                                errors,

                                touched,

                                handleChange,

                                handleBlur,

                                handleSubmit,})=>(
                                    <form onSubmit={handleSubmit}>

                                        <label class="block mb-2 text-sm font-medium text-gray-900" for="user_avatar">Upload arquivo</label>
                                            <div className='relative mb-6'>
                                                <input onChange={handleCoverChange} id="file" type="file" placeholder='Artwork' class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" />
                                                <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">As publicidades devem ser apenas ficheiro do tipo png, e jpg inferiores a 2Mb</div>
                                            </div>
                                        <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Nome do genero</label>
                                        <ErrorMessage style={{fontSize: 12, color: 'red'}} name="genre" component="div"/>
                                        <div class="relative mb-6">
                                            <input type="text"  onChange={handleChange} onBlur={handleBlur} value={values.genre} name="genre" id="input-group-1"  placeholder="Nome do genero" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                        </div>
                                        <div class="relative mb-6 flex flex-row">
                        
                                            <button type="submit" disabled={isSubmitting}  class="h-full text-sm p-2 font-medium bg-amber-500 rounded">
                                                <span className="text-white">
                                                    Adicionar banner
                                                </span>
                                            </button>
                                                
                                        </div>
                                    
                                    </form>
                            )}
                        </Formik>
                    </div>

                    
                </div>
            </div>)
   
}