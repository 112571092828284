const PODCAST_GENRES = [
    {label:"Crime", value:"crime"},
    {label:"Tecnologia", value:"Tech"},
    {label:"Programação", value:"Programming"},
    {label:"Comedia",value:"comedy"},
    {label:"Drama", value:"drama"},
    {label:"Saúde", value:"health"},
    {label:"Jornalismo", value:"Journalism"},
    {label:"Cultura", value: "Culture"},
    {label:"Religiao", value:"Religion"},
    {label:"Ajuda pessoal", value:"Self Help"},
    {label:"Desporto", value:"Sport"},
  ];
  export default PODCAST_GENRES;