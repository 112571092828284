import { useState, useEffect, useCallback } from 'react';
import '../../Styles/ArtistRoot.css';

import COUNTRIES from '../../utils/Countries';
import PODCAST_GENRES from '../../utils/PodcastGender';

import {AiOutlinePlusCircle} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import izenuAPI from '../../services/izenuAPI';

export default function Podcasters(){
    const [creatingAccount, setCriatingAccount] = useState(false);
    const [artists, setArtists] = useState([]);
    const [loading, setLoading] = useState(true);

    const insertArtist = useCallback(async (data)=>{
        setCriatingAccount(true);
        try{
            const {data: r} = await izenuAPI.post('https://www.izenu.ao/api/distributor/artist/createPodcasterAccount.php', data);
            if(!r.response){
                alert("Impossível adicionar artista, tenta mais tarde!");
                return;
            }
            const {data:emailResp} = await axios.post('https://server.izenu.net/emails/emails/new-artist-account/', {pwd: data.pwd1, user_email: data.email});
            
            if(!emailResp.response){
                alert('Conta criada com sucesso, mas não foi possível enviar as crêdenciais de acesso no email!');
                return;
            }

            alert('Conta criada com sucesso, as crêdenciais de acesso foram enviadas no email do artista!');

        }catch(error){
            alert('Ocorreu um erro ao criar conta do artista!');
            setCriatingAccount(false);
            console.log(error);
        }
        setCriatingAccount(false);
      
    });

    const listArtists = useCallback(async()=>{
        setLoading(true);
        const distributor_id = localStorage.getItem('id');
        const END_POINT = 'https://www.izenu.ao/api/distributor/artist/listPodcasters.php?distributor_id='+distributor_id;
        const {data:r} = await izenuAPI.get(END_POINT);
        if(r.response){
            setArtists(r.playlists);
        }

        setLoading(false);
    });
    
    const renderUsers = useCallback(()=>{
        
        if(artists.length > 0){
               
                return   (<>{artists.map((e,i)=>{ 
                        return(
                            <Link to={`/podcaster/${e.artist_id}`}>  
                                <div className="border p-2 flex gap-5 flex-row mb-3 items-center  rounded-lg">
                                    <div className="border rounded-full bg-gray-300 h-16 w-16">
                                    {e.cover!="" && e.cover!=null && e.cover != undefined?(
                                        <img src={e.cover} style={{borderRadius:'100%', objectFit:'cover', width:'100%', height:'100%'}} className='box-shadow'/>

                                    ):(
                                        <svg class="text-gray-500 dark:text-gray-400 h-16 w-16" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                                        </svg>
                                    )}
                                                                            
                                    </div>
                                    
                                    <div>
                                        <h3 className="p-0 m-0 text-left">{e.artist_name}</h3>
                                        <h3 className="p-0 m-0 text-left">{e.first_name}</h3>
                                    </div>
                                </div>
                            </Link>)
                    })}</>)
                
        }else{
            return (<div className="justify-center items-center  h-full flex">
                    
                        <h1 className="text-grey-500 font-light text-xl">Nenhum podcaster encontrado</h1>
                        
                    </div>)
        }
       
    },[artists]);

    useEffect(()=>{
        listArtists();
    },[]);
    
    if(creatingAccount){
        return (<div className="justify-center block items-center flex flex-col h-full flex-1 p-5 rounded-lg" style={{height:'100%'}}>
                    <div role="status">
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <h1 className="text-amber-500 font-light text-3xl">A criar conta...</h1>
                    </div>
                </div>)
    }
        
    if(loading){
        return (<div className="justify-center block items-center flex flex-col h-full flex-1 p-5 rounded-lg" style={{height:'100%'}}>
                    <div role="status">
                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <h1 className="text-amber-500 font-light text-3xl">A carregar...</h1>
                    </div>
                </div>)
    }
    return (<div className="p-10">
                <div className="p-10 flex flex-row space-x-4">
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Pocasters
                            </h1>
                         </div>
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Adicionar podcaster
                            </h1>
                         </div>
                </div>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative flex-1 max-h-96 overflow-y-auto gap-2  p-5">


                        {renderUsers()}
                        
                    </div>

                    <div className="relative flex-1 gap-2 border p-5  overflow-y-auto  rounded-lg max-h-96">
                        <Formik 
                            initialValues={{
                                artist_name:'',
                                first_name: '',
                                last_name: '',
                                email:'',
                                phone:'',
                                pwd1:'',
                                pwd2:'',
                                city:'',
                                bio:'',
                                link:'',
                                gender:'male',
                                birth_date: '',
                                genre: '',
                                country:''
                    
                            }}
                                onSubmit={(values, { setSubmitting, resetForm }) =>{
                        
                                    //alert(JSON.stringify(values, null, 2));
                                        //resetForm({values:''});
                                    
                                    insertArtist(values);
                                }}
                            validate={values => {
                                            
                                const errors = {};
                                //
                                if (values.pwd1!= values.pwd2) {
                                    errors.pwd1 = 'Palavras passe não coencidem!';
                                    errors.pwd2 = 'Palavras passe não coencidem!';
                                }
                                return errors;
                    
                            }}
                                validationSchema={Yup.object({
                                    artist_name:Yup.string().max(50, 'No maximo 13 caracter').required('Campo obrigatorio '),
                                    first_name:Yup.string().max(50, 'No minimo 13 caracteres').required('Campo obrigatorio'),
                                    last_name:Yup.string().max(50, 'No minimo 13 caracteres').required('Campo obrigatorio'),
                                    email:Yup.string().email('Endereço de email invalido').required('Campo obrigatorio'),
                                    phone:Yup.string().max(13, 'No maxopm 13 caracteres').required('Campo obrigatorio'),
                                    
                                    pwd1:Yup.string().min(5, 'No minimo 5 caracteres').required('Campo obrigatorio'),
                                    pwd2:Yup.string().min(5, 'No minimo 5 caracteres').required('Campo obrigatorio'),
                                })}
                                
                            >
                                {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                                <form onSubmit={(e)=>{e.preventDefault(); handleSubmit();}}>
                                

                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Nome do podcast</label>
                                {errors.artist_name && touched.artist_name ? (<span className='p-2 text-amber-500'>{errors.artist_name}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
                                    </svg>

                                    </div>
                                    <input  onChange={handleChange} onBlur={handleBlur} value={values.artist_name} name="artist_name" type="text" placeholder="Nome do podcast" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>
                                
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Nome</label>
                                {touched.first_name && errors.first_name ? (<span className='p-2 text-amber-500'>{errors.first_name}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
                                        </svg>
                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.first_name} name="first_name" type="text" placeholder="Primeiro nome do artista" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>

                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Ultimo nome</label>
                                {touched.last_name && errors.last_name ? (<span className='p-2 text-amber-500'>{errors.last_name}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M12 20a7.966 7.966 0 0 1-5.002-1.756l.002.001v-.683c0-1.794 1.492-3.25 3.333-3.25h3.334c1.84 0 3.333 1.456 3.333 3.25v.683A7.966 7.966 0 0 1 12 20ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10c0 5.5-4.44 9.963-9.932 10h-.138C6.438 21.962 2 17.5 2 12Zm10-5c-1.84 0-3.333 1.455-3.333 3.25S10.159 13.5 12 13.5c1.84 0 3.333-1.455 3.333-3.25S13.841 7 12 7Z" clip-rule="evenodd"/>
                                        </svg>
                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.last_name} name="last_name" type="text" placeholder="Ultimo nome do artista" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>
                                
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Data de nascimento</label>

                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                                        </svg>
                                    </div>
                                    <input name="birth_date" datepicker id="default-datepicker" type="date" onChange={handleChange} onBlur={handleBlur} value={values.birth_date} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-amber-500 dark:focus:border-amber-500" placeholder="Select date"/>
                                </div>

                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Genero</label>
                                <div class="relative mb-6">        
                                    <select onChange={handleChange} onBlur={handleBlur} value={values.gender} name="genre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                        <option default value="male">Masculino</option>
                                        <option value="female">Femenino</option>
                                    </select>
                                </div>

                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Email</label>
                                {touched.email && errors.email ? (<span className='p-2 text-amber-500'>{errors.email}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                        <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                                            <path d="m10.036 8.278 9.258-7.79A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                                            <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                                        </svg>
                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.email} name="email" type="text" placeholder="Email do artista" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>

                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Contacto *brigatorio</label>
                                {touched.phone && errors.phone ? (<span className='p-2 text-amber-500'>{errors.phone}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z"/>
                                    </svg>


                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.phone} name="phone" type="text" placeholder="Ultimo nome do artista" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>

                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Genero de podcast</label>
                                <div class="relative mb-6">        
                                    
                                    <select onChange={handleChange} onBlur={handleBlur} value={values.genre} name="genre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                            {PODCAST_GENRES.map((e,i)=>{
                                                
                                                return i==0?(<option  default key={JSON.stringify(i)} value={e.value}>{e.label}</option>):(<option key={JSON.stringify(i)} value={e.value}>{e.label}</option>);
                                            })}
                                        </select>   
                                    
                                </div>
                                
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">País de origem</label>
                                <div class="relative mb-6">        
                                    
                                    <select onChange={handleChange} onBlur={handleBlur} value={values.country} name="genre" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                        {COUNTRIES.map((e,i)=>{
                                            
                                            return i==0?(<option  default key={JSON.stringify(i)} value={e.name}>{e.name}</option>):(<option key={JSON.stringify(i)} value={e.name}>{e.name}</option>);
                                        })}
                                    </select>   
                                    
                                </div>
                            
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Cidade</label>
                                {touched.city && errors.city ? (<span className='p-2 text-amber-500'>{errors.city}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M11.906 1.994a8.002 8.002 0 0 1 8.09 8.421 7.996 7.996 0 0 1-1.297 3.957.996.996 0 0 1-.133.204l-.108.129c-.178.243-.37.477-.573.699l-5.112 6.224a1 1 0 0 1-1.545 0L5.982 15.26l-.002-.002a18.146 18.146 0 0 1-.309-.38l-.133-.163a.999.999 0 0 1-.13-.202 7.995 7.995 0 0 1 6.498-12.518ZM15 9.997a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0Z" clip-rule="evenodd"/>
                                    </svg>

                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.city} name="city" type="text" placeholder="Cidade do artista" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>
                                    
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Link</label>
                                {touched.link && errors.link ? (<span className='p-2 text-amber-500'>{errors.link}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                                    </svg>


                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.link} name="link" type="text" placeholder="Link" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>
                                
                                
                                
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Biografia</label>
                                {touched.bio && errors.bio ? (<span className='p-2 text-amber-500'>{errors.bio}</span>) : null}
                                <div class="relative mb-6">
                                
                                    
                                    <textarea onChange={handleChange} onBlur={handleBlur} value={values.bio} name="bio" type="text" rows="4" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Biografia do artista..."></textarea>

                                </div>
                                
                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Palavra passe</label>
                                {touched.pwd1 && errors.pwd1 ? (<span className='p-2 text-amber-500'>{errors.pwd1}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z" clip-rule="evenodd"/>
                                    </svg>


                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.pwd1} name="pwd1" type="text" placeholder="Palavra passe" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>


                                <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Digite novamente a palavra passe</label>
                                {touched.pwd2 && errors.pwd2 ? (<span className='p-2 text-amber-500'>{errors.pwd2}</span>) : null}
                                <div class="relative mb-6">
                                    <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                        <path fill-rule="evenodd" d="M8 10V7a4 4 0 1 1 8 0v3h1a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h1Zm2-3a2 2 0 1 1 4 0v3h-4V7Zm2 6a1 1 0 0 1 1 1v3a1 1 0 1 1-2 0v-3a1 1 0 0 1 1-1Z" clip-rule="evenodd"/>
                                    </svg>

                                    </div>
                                    <input onChange={handleChange} onBlur={handleBlur} value={values.pwd2} name="pwd2" type="text" placeholder="Repete a palavra passe" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                                </div>
                        
                                <div class="relative mb-6">
                                    <button type="submit" class="h-full text-sm p-2 font-medium bg-amber-500 rounded">
                                        <span className="text-white">
                                            Adicionar Artista
                                        </span>
                                    </button>
                                </div>
                                </form>)}
                        </Formik>
                    </div>
                </div>
            </div>);
   
}