
import React, { useCallback,useState, useEffect } from 'react';
import { useFormik } from 'formik';
import izenuAPI from '../../services/izenuAPI';

import axios from 'axios';
import { Alert } from '@mui/material';
import AdsBannerCard from './components/AdsBannerCard';
import AdsArtworkCard from './components/AdsArtworkCard';
import AdsMiniPlayerCard from './components/AdsMiniPlayerCard';

const uuid = () =>{

    const alfaNumeric = '1234567890ABCDEFGHIJKLMNOPQRSTUVXWYZabcdefghijklmnopkrstuvxwyz';
    let str = alfaNumeric.split("");
    let str2 = '';
    for(var i = 0; i < 8; i++){
        str2 += '' + str[Math.ceil(Math.random() * str.length)];
    }
    return str2;
}
export default function Ads(){

    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([]);
    const [playerBanners, setPlayerBanners] = useState([]);
    const [artworkBanners, setArtworkBanners] = useState([]);

    const [file, setFile] = useState(null);
    const [image, setImg] = useState(null);
    const [ads, setADS] = useState(null);
    const [adsTime, setAdsTime] = useState("");
    const [adsLink, setAdsLink] = useState("");
    const [desc, setDesc] = useState("");
    const [title, setTitle] = useState("");

    //Artwork
    const [fileArtwork, setFileArtwork] = useState(null);
    const [adsArtworkTime, setAdsArtworkTime] = useState("");
    const [adsArtworkLink, setAdsArtworkLink] = useState("");
    const [descArtwork, setDescArtwork]       = useState("");
    const [titleArtwork, setTitleArtwork]       = useState("");

    //Player
    const [filePlayer, setFilePlayer] = useState(null);
    const [adsPlayerTime, setAdsPlayerTime] = useState("");
    const [adsPlayerLink, setAdsPlayerLink] = useState("");
    const [descPlayer, setDescPlayer]       = useState("");
    const [titlePlayer, setTitlePlayer]       = useState("");

    //Fetch current ads
    const fetchAppAds = useCallback(async ()=>{
        setLoading(true);
        try{    
            const {data: r} = await izenuAPI.get('https://www.izenu.ao/api/admin/ads/fetch.php');
            if(r.response){
                setBanners(r.ads);
                setPlayerBanners(r.player);
                setArtworkBanners(r.artwork);
                //console.log(r);
            }
            setLoading(false);
        }catch(err){
            console.log(err);
            setLoading(false);
        };
        //https://img.gs/pqgzqcgmgv/quality=low/https://dpagz47n4g1lw.cloudfront.net/users/".$value['author_id']."/posts/".$folder."/".$file['file']
    });

    useEffect(()=>{
        fetchAppAds();    
        //uuid();
    },[]);

    const formik = useFormik({

        initialValues: {
   
          email: '',
          pwd:'',
          contact: '',
   
        },
   
        onSubmit: values => {
            //registerUser(values)
   
        },
   
    });

    //Upload banner
    const uploadBanner = useCallback(async()=>{
        if(file ==  null){
            alert("Não selecionou nenhuma imagem valida!");
            return;
        }
        if(title ==  ""){
            alert("O titulo é um campo obrigatorio");
            return;
        }
        const formData = new FormData();
        const id = uuid();
        const d = new Date();
        const date = {day: d.getUTCDate(), year: d.getUTCFullYear(), month: d.getUTCMonth() + 1};
        const fileType = file.type.split('/')[1];

        const fileName = `izenu_ads_${date.year}_${date.month}_${date.day}_${id}.${fileType}`;
        const prefix = `banner_ads/`;

        formData.append(`files`, file);
        formData.append(`key`, prefix + fileName);

        try{
            const {data: responseData} = await axios.post(`https://server.izenu.net/posts/posts/upload-ads`, formData,
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    }
                }
            );
                
            const { data: result } = await izenuAPI.post(
                `https://www.izenu.ao/api/admin/ads/insert.php`,
                {
                    dsc: desc,
                    user_id:4,
                    uri: fileName,
                    title: title,
                    link: adsLink,
                    exp_date: adsTime

                }
            );

            if(result.response){
                setDesc("");
                setAdsLink("");
                setAdsTime("");
                setTitle("");
               
                console.log('Inserted');
            }else{
                console.log('Failed during insert');
            }
        }catch(err){
            console.log(err.code);
        };
        
        
    }, [image, ads, adsLink, adsTime, title, desc]);

    const onChangeFile = useCallback((e)=>{
        const fileType = e.target.files[0].type;
        const fileSize = e.target.files[0].size;

        if(fileType != "image/png" && fileType != "image/jpeg" && fileType != "image/jpg"){
            alert("Ficheiro invalido");
            return;
        }
        if(fileSize *  0.000001 > 3.5 ){
            alert("Ficheiro deve ser inferior a 3Mb");
            return;
        }
        setFile(e.target.files[0]);
        const reader = new FileReader();
            reader.onload = ()=>{
                setImg(reader.result);
            }
            reader.readAsDataURL(e.target.files[0]);
        //console.log(e.target.files[0]);
    });


    const onChangeArtworkFile = useCallback((e)=>{
        const fileType = e.target.files[0].type;
        const fileSize = e.target.files[0].size;

        if(fileType != "image/png" && fileType != "image/jpeg" && fileType != "image/jpg"){
            alert("Ficheiro invalido");
            return;
        }
        if(fileSize *  0.000001 > 3.5 ){
            alert("Ficheiro deve ser inferior a 3Mb");
            return;
        }
        setFileArtwork(e.target.files[0]);
        const reader = new FileReader();
            reader.onload = ()=>{
                setImg(reader.result);
            }
            reader.readAsDataURL(e.target.files[0]);
        //console.log(e.target.files[0]);
    });
    const onChangePlayerFile = useCallback((e)=>{
        const fileType = e.target.files[0].type;
        const fileSize = e.target.files[0].size;

        if(fileType != "image/png" && fileType != "image/jpeg" && fileType != "image/jpg"){
            alert("Ficheiro invalido");
            return;
        }
        if(fileSize *  0.000001 > 3.5 ){
            alert("Ficheiro deve ser inferior a 3Mb");
            return;
        }
        setFilePlayer(e.target.files[0]);
        const reader = new FileReader();
            reader.onload = ()=>{
                setImg(reader.result);
            }
            reader.readAsDataURL(e.target.files[0]);
        //console.log(e.target.files[0]);
    });
     //Upload banner
     const uploadPlayerAds = useCallback(async()=>{
        if(filePlayer ==  null){
            alert("Não selecionou nenhuma imagem valida!");
            return;
        }
        if(titlePlayer ==  ""){
            alert("O titulo é um campo obrigatorio");
            return;
        }
        const formData = new FormData();
        const id = uuid();
        const d = new Date();
        const date = {day: d.getUTCDate(), year: d.getUTCFullYear(), month: d.getUTCMonth() + 1};
        const fileType = filePlayer.type.split('/')[1];

        const fileName = `izenu_ads_player_${date.year}_${date.month}_${date.day}_${id}.${fileType}`;
        const prefix = `player_ads/`;

        formData.append(`files`, filePlayer);
        formData.append(`key`, prefix + fileName);

        try{
            const {data: responseData} = await axios.post(`https://server.izenu.net/posts/posts/upload-ads`, formData,
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    }
                }
            );
                
            const { data: result } = await izenuAPI.post(
                `https://www.izenu.ao/api/admin/ads/insertPlayerAds.php`,
                {
                    info: "",
                    user_id:4,
                    uri: fileName,
                    title: titlePlayer,
                    link: adsPlayerLink,
                    exp_date: adsPlayerTime

                }
            );

            if(result.response){
                
                setAdsPlayerLink("");
                setAdsPlayerTime("");
                setTitlePlayer("");
                console.log('Inserted');
            }else{
                console.log('Failed during insert');
            }
        }catch(err){
            console.log(err.code);
        };
        
        
    }, [filePlayer, adsPlayerLink, adsPlayerTime, titlePlayer]);


     //Upload banner
     const uploadArtworkAds = useCallback(async()=>{
        if(fileArtwork ==  null){
            alert("Não selecionou nenhuma imagem valida!");
            return;
        }
        if(titleArtwork ==  ""){
            alert("O titulo é um campo obrigatorio");
            return;
        }
        const formData = new FormData();
        const id = uuid();
        const d = new Date();
        const date = {day: d.getUTCDate(), year: d.getUTCFullYear(), month: d.getUTCMonth() + 1};
        const fileType = fileArtwork.type.split('/')[1];

        const fileName = `izenu_ads_artwork_${date.year}_${date.month}_${date.day}_${id}.${fileType}`;
        const prefix = `artwork_ads/`;

        formData.append(`files`, fileArtwork);
        formData.append(`key`, prefix + fileName);

        try{
            const {data: responseData} = await axios.post(`https://server.izenu.net/posts/posts/upload-ads`, formData,
                {
                    headers: {
                    'Content-Type': 'multipart/form-data',
                    }
                }
            );
                
            const { data: result } = await izenuAPI.post(
                `https://www.izenu.ao/api/admin/ads/insertArtworkAds.php`,
                {
                    info: "",
                    user_id:4,
                    uri: fileName,
                    title: titleArtwork,
                    link: adsArtworkLink,
                    exp_date: adsArtworkTime

                }
            );

            if(result.response){
                
                setAdsArtworkLink("");
                setAdsArtworkTime("");
                setTitleArtwork("");
                console.log('Inserted');
            }else{
                console.log('Failed during insert');
            }
        }catch(err){
            console.log(err.code);
        };
        
        
    }, [fileArtwork, adsArtworkLink, adsArtworkTime, titleArtwork]);


    const deleteAds = useCallback(async(obj)=>{

        try{
            const {data: r} = await izenuAPI.post("https://www.izenu.ao/api/ads/delete.php",{ads_id:obj.id, type:obj.type});

            if(r.response){
                alert("Apagado com sucesso!");
                return;
            }

            alert("Impossivel apagar, tenta mais tarde!");
        }catch(error){
            alert("Impossivel apagar, tenta mais tarde!");
        }
        
    },[adsTime,  ads, adsLink]);

    const renderPlayerAds = useCallback(()=>{

        if(playerBanners.length > 0){
            return (<div className="grid grid-cols-4 gap-4">{playerBanners.map((e,i)=>{
                            return(
                               <AdsMiniPlayerCard item={e}/>
                            )
                        })}</div>)
        }else{
            return(
                <div className="p-2 flex gap-5 h-full w-full flex-row mb-3 items-center  rounded-lg">
                    <span className="text-center block w-full text-sm text-gray-500">
                        Ainda sem publicidades
                    </span> 
                </div>
            )
        }
        
    },[playerBanners]);

    const renderArtworkAds = useCallback(()=>{

        if(artworkBanners.length > 0){
            return (<div className="grid grid-cols-3 gap-3">
                {artworkBanners.map((e,i)=>{
                    
                    return(
                        <AdsArtworkCard item={e}/>
                    )
                })}
            </div>   )
        }else{
            return(
                <div className="p-2 flex gap-5 h-full w-full flex-row mb-3 items-center  rounded-lg">
                    <span className="text-center block w-full text-sm text-gray-500">
                        Ainda sem publicidades
                    </span> 
                </div>
            )
        }
        
    },[artworkBanners]);

    if(loading){
        return (<div className="justify-center items-center flex flex-col h-full border p-5 rounded-lg">
                        <div role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    <h1 className="text-amber-500 font-light text-3xl">A carregar...</h1>
                    
                </div>)
    }
    return (
        <div className="p-10 relative">
                <div className="p-10 flex flex-row space-x-4">
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Publicidades
                            </h1>
                         </div>
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Adicionar publicidade
                            </h1>
                         </div>
                </div>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative border flex-1 max-h-96 overflow-y-auto gap-2  p-5">
                        <div className="grid grid-cols-2 gap-4">
                            {banners.map((e,i)=>{
                                return(
                                  <AdsBannerCard item={e}/>
                                )
                            })}
                        </div>    
                    </div>

                    <div className="relative flex-1 max-h-96 overflow-y-auto gap-2 border p-5 rounded-lg">
                        <form onSubmit={(e)=>{e.preventDefault(); uploadBanner()}} className='uploadForm'>

                            <label class="block mb-2 text-sm font-medium text-gray-900" for="user_avatar">Upload arquivo</label>
                            <div className='relative mb-6'>
                                <input onChange={onChangeFile} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file"/>
                                <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">As publicidades devem ser apenas ficheiro do tipo png, e jpg inferiores a 2Mb</div>
                            </div>
                            <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Titulo</label>
                            <div class="relative mb-6">
                                <input type="text" name="title"  value={title} onChange={(e)=>{setTitle(e.target.value);}} id="input-group-1"  placeholder="Titulo" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                            </div>
                            <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Descrição</label>
                            <div className="relative mb-6">
                                <textarea id="message" rows="4" onChange={(e)=>setDesc(e.target.value)} value={desc} class="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-amber-500 focus:border-amber-500  dark:placeholder-gray-400 dark:focus:ring-amber-500 dark:focus:border-amber-500" placeholder="Adicionar descrição a publicidade"></textarea>
                            </div>
                            <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Link</label>
                            <div class="relative mb-6">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                                    </svg>
                                </div>
                                <input type="text" name="link" onChange={(e)=>setAdsLink(e.target.value)} value={adsLink} id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Link do ads"/>
                            </div>
                           
                            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900">Tempo de duração</label>
                            <div class="relative mb-6">
                                <select id="countries" onChange={(e)=>setAdsTime(e.target.value)}  class="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-amber-500 block p-2.5  dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                    <option default value={""}>Seleciona a tua opção</option>
                                    <option value={'15d'}>15 Dias</option>
                                    <option value={'1M'}>1 Mês</option>
                                    <option value={'2M'}>2 Meses</option>
                                    <option value={'3M'}>3 Meses</option>
                                    <option value={'6M'}>6 Meses</option>
                                    <option value={'1Y'}>1 Ano</option>
                                </select>
                            </div>
                            <div class="relative mb-6 flex flex-row">

                            {file!=null?(
                                          
                                            <button type="submit" class="h-full text-sm p-2 font-medium bg-amber-500 rounded">
                                                <span className="text-white">
                                                    Adicionar banner
                                                </span>
                                            </button>
                                        ):(<></>)}
                                
                            </div>
                        </form>
                    </div>

                    
                </div>
                {/**Player */}
                <div className="p-10 flex flex-row space-x-4">
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Publicidades no leitor de reprodução
                            </h1>
                         </div>
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Adicionar publicidade
                            </h1>
                         </div>
                </div>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative border flex-1 max-h-96 overflow-y-auto gap-2  p-5">
                        
                            {renderPlayerAds()}
                       
                    </div>

                    <div className="relative flex-1 max-h-96 overflow-y-auto gap-2 border p-5 rounded-lg">
                        <form onSubmit={(e)=>{e.preventDefault(); uploadPlayerAds()}} className='uploadForm'>

                            <label class="block mb-2 text-sm font-medium text-gray-900" for="user_avatar">Upload arquivo</label>
                            <div className='relative mb-6'>
                                <input onChange={onChangePlayerFile} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file"/>
                                <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">As publicidades devem ser apenas ficheiro do tipo png, e jpg inferiores a 2Mb</div>
                            </div>
                            <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Titulo</label>
                            <div class="relative mb-6">
                                <input type="text" name="title"  value={titlePlayer} onChange={(e)=>{setTitlePlayer(e.target.value);}} id="input-group-1"  placeholder="Titulo" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                            </div>
                            <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Link</label>
                            <div class="relative mb-6">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                                    </svg>
                                </div>
                                <input type="text" name="link" onChange={(e)=>setAdsPlayerLink(e.target.value)} value={adsPlayerLink} id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Link do ads"/>
                            </div>
                           
                            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900">Tempo de duração</label>
                            <div class="relative mb-6">
                                <select id="countries" onChange={(e)=>setAdsPlayerTime(e.target.value)}  class="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-amber-500 block p-2.5  dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                    <option default value={""}>Seleciona a tua opção</option>
                                    <option value={'15d'}>15 Dias</option>
                                    <option value={'1M'}>1 Mês</option>
                                    <option value={'2M'}>2 Meses</option>
                                    <option value={'3M'}>3 Meses</option>
                                    <option value={'6M'}>6 Meses</option>
                                    <option value={'1Y'}>1 Ano</option>
                                </select>
                            </div>
                            <div class="relative mb-6 flex flex-row">

                            {filePlayer!=null?(
                                          
                                            <button type="submit" class="h-full text-sm p-2 font-medium bg-amber-500 rounded">
                                                <span className="text-white">
                                                    Adicionar banner
                                                </span>
                                            </button>
                                        ):(<></>)}
                                
                            </div>
                        </form>
                    </div>

                    
                </div>
                {/**ARtwork */}
                <div className="p-10 flex flex-row space-x-4">
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Publicidades na capa da música
                            </h1>
                         </div>
                         <div className="relative flex-1 gap-2 border py-5 rounded-lg">
                            <h1 className="font-bold">
                                Adicionar publicidade
                            </h1>
                         </div>
                </div>
                <div className="p-10 flex flex-row space-x-4">

                    <div className="relative border flex-1 max-h-96 overflow-y-auto gap-2  p-5">
                        {renderArtworkAds()} 
                    </div>

                    <div className="relative flex-1 max-h-96 overflow-y-auto gap-2 border p-5 rounded-lg">
                        <form onSubmit={(e)=>{e.preventDefault(); uploadArtworkAds()}} className='uploadForm'>

                            <label class="block mb-2 text-sm font-medium text-gray-900" for="user_avatar">Upload arquivo</label>
                            <div className='relative mb-6'>
                                <input onChange={onChangeArtworkFile} class="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" aria-describedby="user_avatar_help" id="user_avatar" type="file"/>
                                <div class="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">As publicidades devem ser apenas ficheiro do tipo png, e jpg inferiores a 2Mb</div>
                            </div>
                            <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Titulo</label>
                            <div class="relative mb-6">
                                <input type="text" name="title"  value={titleArtwork} onChange={(e)=>{setTitleArtwork(e.target.value);}} id="input-group-1"  placeholder="Titulo" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-amber-500 block w-full ps-10 p-2.5"/>
                            </div>
                            <label for="input-group-1" class="block mb-2 text-sm font-medium text-gray-900 text-left">Link</label>
                            <div class="relative mb-6">
                                <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                    <svg class="w-4 h-4 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.213 9.787a3.391 3.391 0 0 0-4.795 0l-3.425 3.426a3.39 3.39 0 0 0 4.795 4.794l.321-.304m-.321-4.49a3.39 3.39 0 0 0 4.795 0l3.424-3.426a3.39 3.39 0 0 0-4.794-4.795l-1.028.961"/>
                                    </svg>
                                </div>
                                <input type="text" name="link" onChange={(e)=>setAdsArtworkLink(e.target.value)} value={adsArtworkLink} id="input-group-1" className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-amber-500 block w-full ps-10 p-2.5" placeholder="Link do ads"/>
                            </div>
                           
                            <label for="countries" class="block mb-2 text-sm font-medium text-gray-900">Tempo de duração</label>
                            <div class="relative mb-6">
                                <select id="countries" onChange={(e)=>setAdsArtworkTime(e.target.value)}  class="bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg focus:ring-amber-500 block p-2.5  dark:focus:ring-amber-500 dark:focus:border-amber-500">
                                    <option default value={""}>Seleciona a tua opção</option>
                                    <option value={'15d'}>15 Dias</option>
                                    <option value={'1M'}>1 Mês</option>
                                    <option value={'2M'}>2 Meses</option>
                                    <option value={'3M'}>3 Meses</option>
                                    <option value={'6M'}>6 Meses</option>
                                    <option value={'1Y'}>1 Ano</option>
                                </select>
                            </div>
                            <div class="relative mb-6 flex flex-row">

                            {fileArtwork!=null?(
                                          
                                            <button type="submit" class="h-full text-sm p-2 font-medium bg-amber-500 rounded">
                                                <span className="text-white">
                                                    Adicionar banner
                                                </span>
                                            </button>
                                        ):(<></>)}
                                
                            </div>
                        </form>
                    </div>

                    
                </div>
                
            </div>);

}
