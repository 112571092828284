import { useContext, useCallback, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';

import '../../Styles/index.css';

import izenuAPI from '../../services/izenuAPI';
import { Alert } from '@mui/material';

const Player = ({musicPlayer, playing, setPlaying})=>{
   
    /*useEffect(()=>{
        if(musicPlayer!= null){

        
        if(musicPlayer.title != music.title && musicPlayer!= null){
            setPlaying(true);
        }
    }   
    },[musicPlayer])*/
    
    const handleMusicCover = useCallback((cover)=>{
        if(cover == null || cover == undefined){
            return (
                <div style={{width:45, backgroundColor:"#777", height:45, borderRadius:4}}>

                </div>);
        }else{
            return (
                <img class="w-8 h-8 border" src={cover}/>
          
            );
        }
    });

    if(musicPlayer != null){
        return ( <div class="border  w-full ">
                    <ul class="w-full bg-gray-900">
                        <li class="pb-3 sm:pb-4">
                            <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                <div class="flex-shrink-0">
                                    {handleMusicCover(musicPlayer.cover_url.original_photo_url)}
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-100 truncate ">
                                        {musicPlayer.title}
                                    </p>
                                    <p class="text-sm text-gray-200 truncate">
                                        {musicPlayer.singer}
                                        
                                    </p>
                                </div>
                                <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {playing?(
                                        <span onClick={()=>{
                                            if(playing){
                                                setPlaying(false);
                                            }
                                        }} class="rounded-full cursor-pointer flex items-center justify-center w-7 h-7 bg-amber-500">
                                            <svg class="w-4 h-4 text-gray-800 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M8 5a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H8Zm7 0a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1Z" clip-rule="evenodd"/>
                                            </svg>

                                        </span>
                                    ):(
                                        <span onClick={()=>{
                                            
                                                setPlaying(true);
                                            
                                        }}  class="rounded-full cursor-pointer flex items-center justify-center w-7 h-7 bg-amber-500">
                                    <svg class="w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M8.6 5.2A1 1 0 0 0 7 6v12a1 1 0 0 0 1.6.8l8-6a1 1 0 0 0 0-1.6l-8-6Z" clip-rule="evenodd"/>
                                        </svg>


                                    </span>
                                    )}
                                    
                                </div>
                            </div>
                        
                        </li>
                    </ul>
                    <ReactPlayer
                        className='react-player'
                        url={musicPlayer.file}
                        width='100%'
                        height='100%'
                        playing={playing}
                    />
            
                </div>)
    }
    return null;
}

export default function ListMusics(){

    const [music, setMusic] = useState([]);
    const [opt, setOpt] = useState(null);
    const [data, setData] = useState({});
    const [musicId, setMusicId] = useState(null);
    const [modal, setModal] = useState(false);
    const [playing, setPlaying] = useState(false);  
    const [musicPlayer, setMusicPlayer] = useState(null);  
    const [fetchingData, setFetchingData] = useState(true);

    const fetchMusic = useCallback(async ()=>{
        try{
            const {data: resp} = await izenuAPI.get('https://www.izenu.ao/api/music/fetchUnreviewedMusics.php?limit=30');
            if(resp.response){
                console.log(resp.musics)
                setMusic(resp.musics);
            }
        }catch(error){
            alert("Ocorreu algum erro, tente mais tarde!")
        }   
       
        setFetchingData(false);
    });
    const updateState = useCallback(async (d, type)=>{
        try{

        
        const {data: resp} = await izenuAPI.post('https://www.izenu.ao/api/music/changeState.php', {music_id:d.music_id, state:type});
        
        if(resp.response != true){
            alert('Impossivel aprovar tente mais tarde!');
            return;
        }

        const {data: emailResp} = await izenuAPI.post('https://server.izenu.net/emails/emails/approved-email/', {music_title:d.title, user_email: d.user_email});
        
        if(emailResp.response!= true){
            alert("Música aprovada, mas foi impossível enviar email de confirmação");
            return;
        }
        alert('Música aprovada!');
    }catch(Err){
        console.log(Err);
    }

    },[data, opt]);
    //let { playlist_id } = useParams();
    const handleMusicCover = useCallback((cover)=>{

        if(cover == null || cover == undefined || cover == ""){
            return (
                <div style={{width:45, backgroundColor:"#777", height:45, borderRadius:4}}>

                </div>);
        }else{
            return (
                <img class="w-8 h-8 border" src={cover}/>
          
            );
        }
    });
    const handleMusicState = useCallback((state)=>{
        if(state =="approved"){
            return (<span className='text-blue-500'>Aprovado</span>)
        }else if(state == "rejected"){
            return (<span className='text-rose-500'>Rejeitado</span>)
        }else{
            return (<span className='text-amber-500'>Pendente</span>)
        }
    });
    useEffect(()=>{
        fetchMusic();
    },[]);
    if(fetchingData){
        return (<div className="justify-center items-center flex flex-col h-full border p-5 rounded-lg">
                        <div role="status">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div>
                    <h1 className="text-amber-500 font-light text-3xl">A carregar...</h1>
                    
                </div>)
    }
    return( <>
               <div className="p-10">
            
                    <Player musicPlayer={musicPlayer} playing ={playing} setPlaying={setPlaying}/>
                    <div className="relative flex-1 gap-2 border py-5 rounded-lg max-h-screen overflow-y-auto">
                        <ul class="w-full divide-y divide-gray-200 dark:divide-gray-700">
                            {music!= null?(
                                            <div>
                                                <li class="pb-3 sm:pb-4">
                                                                    <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                                                        <div class="flex-shrink-0">
                                                                            Capa
                                                                        </div>
                                                                        <div class="flex-1 min-w-0">
                                                                            <p class="text-sm font-medium text-left text-gray-900 truncate dark:text-white">
                                                                                Detalhes
                                                                            </p>
                                                                        </div>
                                                                        <div class="flex min-w-0">
                                                                           
                                                                        </div>
                                                                        <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                            <span  class='text-sm text-gray-500 px-2'>
                                                                                Estado
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                {music.map((e, i)=>{
                                                   
                                                        return (<li class="pb-3 sm:pb-4 hover:bg-gray-200">
                                                                    <div class="flex items-center space-x-4 rtl:space-x-reverse">
                                                                        <div  onClick={()=>{
                                                                            setMusicPlayer(e);
                                                                            setPlaying(true);
                                                                            }} class="flex-shrink-0">
                                                                                {handleMusicCover(e.cover_url.original_photo_url)}
                                                                            </div>
                                                                            <div  onClick={()=>{
                                                                                setMusicPlayer(e);
                                                                                setPlaying(true);
                                                                            }}  class="flex-1 min-w-0 cursor-pointer">
                                                                            <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                                                {e.title}
                                                                            </p>
                                                                            <p class="text-sm text-gray-500 cursor-pointer truncate dark:text-gray-400">
                                                                                {e.name || e.singer}
                                                                            </p>
                                                                        </div>
                                                                        <div class="flex min-w-0">
                                                                        <span onClick={()=>{
                                                                                setMusicId(e.music_id);
                                                                                setData({music_id: e.music_id, title:e.title, user_email: e.email});
                                                                                setOpt(1);
                                                                                setModal(true);
                                                                            }} class='text-sm text-blue-600 border-blue-900 mr-5 bg-blue-400/30 border py-1 cursor-pointer px-2 rounded-sm'>
                                                                               Aprovar
                                                                            </span>
                                                                            <span onClick={()=>{
                                                                                setMusicId(e.music_id);
                                                                                setData({music_id: e.music_id, user_email: e.email});
                                                                                setOpt(0); 
                                                                                setModal(true);  
                                                                            }} class='text-sm text-rose-600 border-rose-900 bg-rose-400/30 border py-1 cursor-pointer px-2 rounded-sm'>
                                                                               Reprovar
                                                                            </span>
                                                                        </div>
                                                                        <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                                            {handleMusicState(e.music_state)}
                                                                        </div>
                                                                    </div>
                                                                </li>)
                                                    })
                                                }
                                            </div> 
                                ):(
                                    <div class="py-40">
                                        <h1 class="text-center">Ainda não carregou música</h1>
                                    </div>
                                )
                                
                            }
                        </ul>
                    </div>
                </div>
                {modal?(

                    <div className="modal w-full">
                        <div className="bg-gray-100 pb-5 px-5">
                            <div style={{display:'flex', flexDirection:'row', justifyContent: 'center', padding:12, alignItems:'center'}}>
                                <h3>{opt==1?"Aprovar música":"Remover música"}</h3>
                            </div>
                            <div style={{flex:1, overflowY:'scroll', padding:20, overflowX:'hidden'}}>
                                <p>
                                    Esta ação é irreversivel, tem certeza?
                                </p>
                                <div style={{display:'flex', marginTop:20, justifyContent:'center', alignItems:'center'}}>
                                    <button onClick={()=>{
                                        if(opt==1){
                                            updateState(data, 'approved');
                                        }else{
                                            updateState(data, 'rejected');
                                        }
                                        setModal(false);
                                    }} style={{marginRight:10}} className='btn-blue-round'>
                                        <h4 style={{color:"#fff"}}>Confirmar</h4>
                                    </button>
                                    <button className='btn-grey-round' onClick={()=>setModal(false)}>
                                        <h4 style={{color:"#fff"}}>Cancelar</h4>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                ):(<></>)}
            </>);
}