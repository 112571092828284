
import { useCallback, useState, createContext } from 'react';
import './Styles/App.css';

import {BrowserRouter as Router, createBrowserRouter, RouterProvider, Routes, Route, Redirect, Navigate} from 'react-router-dom';


import { UserContext } from './Context/UserContext';

//Distributor page root
import DistributorRoot from './routes/distributorRoot';

import Dashboard from './routes/distributorRoots/Dashboard';
import Artists from './routes/distributorRoots/Artists';
import Playlists from './routes/distributorRoots/Playlists';
import Settings from './routes/distributorRoots/Settings';
import Upload from './routes/distributorRoots/Upload';
import Playlist from './routes/distributorRoots/Playlist';
import Artist from './routes/distributorRoots/Artist';
import Albums from './routes/distributorRoots/Albums';
import Album from './routes/distributorRoots/Album';
import Podcasters from './routes/distributorRoots/Podcasters';
import UploadPodcasts from './routes/distributorRoots/UploadPodcasts';

import Podcaster from './routes/distributorRoots/Podcaster';
//Admin
import ProfileReports from "./routes/adminRoots/ProfileReports";
import PostsReports from "./routes/adminRoots/PostsReport";
import Admin from "./routes/adminRoots/Admin";
import Ads from "./routes/adminRoots/Ads";
import Distributors from "./routes/adminRoots/Distributors";
import ListBugReports from "./Pages/BugReports/List";
import UpdateBugReports from "./Pages/BugReports/Update";
import ListMusics from './routes/adminRoots/ListMusics';
import Genres from './routes/adminRoots/Genres';
import DashboardOperations from './routes/adminRoots/DashboardOperations';
import DashboardStreams from './routes/adminRoots/DashboardStreams';
import AdminUsers from './routes/adminRoots/AdminUsers';
//Login

import Home from './routes/loginRoots/Home';
import DistributorLogin from './routes/distributorRoots/DistributorLogin';
import AdminLogin from './routes/adminRoots/AdminLogin';
import Lyrics from './routes/adminRoots/Lyrics';
function App() {
  const [user, setUser] = useState(null);
  
  const distributor_rooter = createBrowserRouter([
    {
      path:'/',
      element: <DistributorRoot/>,
      errorElement:<DistributorRoot/>,
      children:[
        { 
          path: '/',
          element: <Dashboard/>
        },
        { 
          path: '/dashboard',
          element: <Dashboard/>
        },
        { 
          path: '/settings',
          element: <Settings/>
        },
        { 
          path: '/artists',
          element: <Artists/>
        },{ 
          path: '/playlists',
          element: <Playlists/>
        },
        { 
          path: '/albums',
          element: <Albums/>
        },
        { 
          path: '/album/:playlist_id',
          element: <Album/>
        },
        { 
          path: '/upload',
          element: <Upload/>
        },
        { 
          path: '/podcasts',
          element: <UploadPodcasts/>
        },
        { 
          path: '/podcasters',
          element: <Podcasters/>
        },
        { 
          path: '/podcaster/:artist_id',
          element: <Podcaster/>
        },
        { 
          path: '/playlist/:playlist_id',
          element: <Playlist/>
        },
        { 
          path: '/artist/:artist_id',
          element: <Artist/>
        }
      ]
    }
  ]);
  const admin_rooter = createBrowserRouter([
    {
      path:'/',
      element: <Admin/>,
      errorElement:<Admin/>,
      children:[
        { 
         
          path: '/streams',
          element: <DashboardStreams/>
        },
        { 
         
          path: '/posts',
          element: <PostsReports/>
        },
        { 
          path: '/genres',
          element: <Genres/>
        },
        { 
          path: '/lyrics',
          element: <Lyrics/>
        },
        { 
          path: '/profiles',
          element: <ProfileReports/>
        },
        { 
          path: '/distributors',
          element: <Distributors/>
        },
        { 
          path: '/ads',
          element: <Ads/>
        },
        {
        
          path:'/',
          element: <DashboardOperations/>
        },
        {
        
          path:'/admin',
          element: <DashboardOperations/>
        },
        {
        
          path:'/admins',
          element: <AdminUsers/>
        },
        { 
          path: '/list-musics',
          element: <ListMusics/>
        },
        { 
          path: '/bug-reports',
          element: <ListBugReports />
        },
        { 
          path: '/bug-reports/:id',
          element: <UpdateBugReports />
        },
      ]
    }
  ]);
  const login_rooter = createBrowserRouter([
    {
      path:'/',
      element: <Home/>,
      errorElement:<Home/>,
      children:[
        { 
          path: '/home',  
          element: <Home/>
        },
        
      ]},
      { 
        path: '/distributor',
        element: <DistributorLogin/>
      },
      { 
        path: '/admin',
        element: <AdminLogin/>
      },
  ]);

  const content = useCallback(()=>{
    const token = localStorage.getItem('user');
    const type  = localStorage.getItem('type');
    
    if(token !==null && token.length > 0){
      if(type == 'distributor'){
        return (<RouterProvider router={distributor_rooter}/>);
      }else if(type=="admin"){
        return (
                <RouterProvider router={admin_rooter}/>
              );
      }
    }else{
      return (<RouterProvider router={login_rooter}/>);
    }
  },[distributor_rooter, admin_rooter, login_rooter]);
  return (
 
      <div className="App">
          <UserContext.Provider value={{user, setUser}}>
            {content()}
          </UserContext.Provider>
      </div> 
  );
}

export default App;
