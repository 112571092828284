
import { useCallback, useState } from "react"
import izenuAPI from "../../../services/izenuAPI";

export default function AdsArtworkCard({item}){
    const [showOption, setShowOption] = useState(false);
    const [loading, setLoading] = useState(false);
    const deleteAds = useCallback(()=>{
        const r = window.confirm("Apagar é irreversivel, tem certeza?")
        if(r == true){
            deleteADS();
            console.log(item);
        }
    });

    const deleteADS = useCallback(async()=>{
        setLoading(true);
       
        try{
            const {data: r} = await izenuAPI.post("https://www.izenu.ao/api/admin/ads/delete.php",{ads_id:item.id, type:'artwork'});
            console.log(r);
            if(r.response){
                alert("Apagado com sucesso!");
                setLoading(false);
                return;
            }

            alert("Impossivel apagar, tenta mais tarde!");
            setLoading(false);
        }catch(error){
            console.log(error);
            alert("Impossivel apagar, tenta mais tarde!");
            setLoading(false);
        }
        
    });

    if(loading){
        return (  <div className="relative cursor-pointer border p-2 flex gap-5 flex-row mb-3 items-center  bg-rose-500/30   rounded-lg">
                  <span className="text-white">
                                        A apagar...
                                    </span>
                </div>);
    }
    
    return (    <div className="border h-auto w-full p-2 flex gap-5 flex-row mb-3 items-center  rounded-lg">
                    <img onClick={()=>setShowOption(true)} className="h-full w-full rounded-lg object-cover" src={item.uri}/>               
                        {showOption && (<div onClick={()=>setShowOption(false)}className="rounded-lg backdrop-blur-sm bg-white/30 flex items-center  justify-center absolute inset-0">
                            <button onClick={()=>deleteAds()} class="text-sm h-10 px-10 font-medium bg-rose-500 rounded">
                            <span className="text-white">
                                Apagar
                            </span>
                        </button>
                    </div>)}
                </div>)

}